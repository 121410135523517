import { Button, ButtonColors } from '@talentech/components';
import { groupByOptions } from '../../utils/constants';
import { setSelectedPublisher } from '../../utils/features/filters/filterSlice';
import { useAppDispatch } from '../../utils/features/hooks';
import { SectionTitle } from '../../utils/styles';

interface Props {
  selectedPublisher: string;
}

export const GroupByPublisher = ({ selectedPublisher }: Props) => {
  const dispatch = useAppDispatch();
  const handleGroupBy = (value: string) => {
    if (value !== selectedPublisher) {
      dispatch(setSelectedPublisher(value));
    }
  };

  return (
    <>
      <SectionTitle size={'small'}>GROUP BY</SectionTitle>
      <div className="btn-group" role="group" aria-label="Group by">
        {groupByOptions.map((button) => (
          <Button
            key={button.value}
            outline={selectedPublisher === button.value ? false : true}
            color={
              selectedPublisher === button.value
                ? ButtonColors.PRIMARY
                : ButtonColors.SECONDARY
            }
            label={button.label}
            onClick={() => handleGroupBy(button.value)}
          />
        ))}
      </div>
    </>
  );
};
