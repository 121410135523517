import React, { useEffect } from 'react';
import {
  hubSpotFormModalClosedEvent,
  hubSpotFormModalOpenedEvent,
  hubSpotFormSubmittedEvent,
  shortDescriptionMaxLength,
  talentech,
} from './constants';
import { SanitizedDirtyHtmlContent } from './domPurifyUtils';
import { AppRoutes, IntegrationOptions } from './enums';
import { IntegrationFormFields } from './formfields/IntegrationFormFields';
import { userSchema } from './formfields/validationSchemas';
import defaultLogo from './images/DefaultLogo.svg';
import {
  AppDetails,
  CustomerDetailsFormat,
  HubSpotFormFormat,
} from './interfaces';

/**
 * Works as a wrapper component that will also handle scrolling to top of the window.
 * @param props the component to wrap
 * @returns a functional component for wrapping components
 */
const Page: React.FC<React.ReactNode> = (
  props: React.PropsWithChildren<React.ReactNode>,
) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <main>{props.children}</main>;
};

function truncateLongText(str: string) {
  return str.length > shortDescriptionMaxLength
    ? str.substring(0, shortDescriptionMaxLength) + '...'
    : str;
}

function removeLastSlash(myUrl: string) {
  if (myUrl.substring(myUrl.length - 1) === '/') {
    myUrl = myUrl.substring(0, myUrl.length - 1);
  }

  return myUrl;
}

const openUrlInNewTab = (url: string): void => {
  const browserWindow = window.open(url, '_blank');
  browserWindow!.focus();
};

export {
  AppRoutes,
  IntegrationOptions,
  Page,
  truncateLongText,
  hubSpotFormModalClosedEvent,
  hubSpotFormModalOpenedEvent,
  hubSpotFormSubmittedEvent,
  talentech,
  IntegrationFormFields,
  userSchema,
  defaultLogo,
  SanitizedDirtyHtmlContent,
  openUrlInNewTab,
  removeLastSlash,
};
export type { AppDetails, CustomerDetailsFormat, HubSpotFormFormat };
