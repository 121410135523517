import { useState } from 'react';
import styled from 'styled-components';
import { defaultLogo } from '../../utils';

type ShieldProps = {
  logo: string;
  showBorder: boolean;
  className?: string;
};

const ShieldStyle = styled.img<{ showBorder: boolean }>`
  border-radius: 0.25rem;
  border: ${(props) => (props.showBorder ? '1px solid #d6d6dc' : 'none')};
`;

const Shield = ({ logo, showBorder, className }: ShieldProps) => {
  const [logoError, setLogoError] = useState<boolean>(false);
  return (
    <ShieldStyle
      className={className}
      showBorder={showBorder}
      onError={() => setLogoError(true)}
      src={logoError ? defaultLogo : logo}
      alt="Company logo"
      width={'64'}
      height={'64'}
    />
  );
};

export default Shield;
