import { talentechTheme } from '@talentech/components';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { store } from './utils/features/store';
import { reactPlugin } from './utils/AppInsights';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import Layout from './routes/index';
import { Toaster } from './components/notifications/Toaster';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={talentechTheme}>
        <AppInsightsContext.Provider value={reactPlugin}>
          <Toaster />
          <Router>
            <Layout />
          </Router>
        </AppInsightsContext.Provider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
