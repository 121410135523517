import {
  HUBSPOT_FORM_BASE_URL,
  HUBSPOT_PORTAL_ID,
  PROD_HUBSPOT_FORM_ID,
  PROD_MARKETPLACE_URL,
  PROD_PEOPLE_CORE_URL,
  PROD_TALENTECH_ADMIN_URL,
  PROD_TALENTECH_ID_URL,
  TEST_AND_DEV_HUBSPOT_FORM_ID,
  TEST_PEOPLE_CORE_URL,
  TEST_TALENTECH_ADMIN_URL,
  TEST_TALENTECH_ID_URL,
} from './constants';

export const getHubspotPortalID = () => {
  return HUBSPOT_PORTAL_ID;
};

export const getHubspotFormID = (origin: string) => {
  switch (origin) {
    case PROD_MARKETPLACE_URL:
      return PROD_HUBSPOT_FORM_ID;
    default:
      return TEST_AND_DEV_HUBSPOT_FORM_ID;
  }
};

export const getTalentechIdUrl = (origin: string) => {
  switch (origin) {
    case PROD_MARKETPLACE_URL:
      return PROD_TALENTECH_ID_URL;
    default:
      return TEST_TALENTECH_ID_URL;
  }
};

export const getPeopleCoreUrl = (origin: string) => {
  switch (origin) {
    case PROD_MARKETPLACE_URL:
      return PROD_PEOPLE_CORE_URL;
    default:
      return TEST_PEOPLE_CORE_URL;
  }
};

export const getTalentechAdminUrl = (origin: string) => {
  switch (origin) {
    case PROD_MARKETPLACE_URL:
      return PROD_TALENTECH_ADMIN_URL;
    default:
      return TEST_TALENTECH_ADMIN_URL;
  }
};

export const getHubspotFormBaseURL = () => {
  return HUBSPOT_FORM_BASE_URL;
};
