import {
  GroupByOptions,
  IntegrationOptions,
  MarketOptions,
  StageOptions,
  TalentechExternalIds,
} from './enums';

export const talentech = 'Talentech';
export const marketplace = 'Marketplace';

export const stageOptions = [
  {
    label: 'All',
    value: StageOptions.FullTalentJourney,
  },
  {
    label: 'Absence',
    value: StageOptions.Absence,
  },
  {
    label: 'Analytics',
    value: StageOptions.Analytics,
  },
  {
    label: 'Archiving',
    value: StageOptions.Archiving,
  },
  {
    label: 'Assessment',
    value: StageOptions.Assessment,
  },
  {
    label: 'ATS',
    value: StageOptions.ATS,
  },
  {
    label: 'Background check',
    value: StageOptions.BackgroundCheck,
  },
  {
    label: 'Candidate feedback',
    value: StageOptions.CandidateFeedback,
  },
  {
    label: 'Chatbot',
    value: StageOptions.Chatbot,
  },
  {
    label: 'Core HR',
    value: StageOptions.CoreHR,
  },
  {
    label: 'Easy apply',
    value: StageOptions.EasyApply,
  },
  {
    label: 'Employee feedback',
    value: StageOptions.EmployeeFeedback,
  },
  {
    label: 'E-signing',
    value: StageOptions.ESigning,
  },
  {
    label: 'Job board',
    value: StageOptions.JobBoard,
  },
  {
    label: 'On/offboarding',
    value: StageOptions.OnOffboarding,
  },
  {
    label: 'Other',
    value: StageOptions.Other,
  },
  {
    label: 'Payroll',
    value: StageOptions.Payroll,
  },
  {
    label: 'Reference check',
    value: StageOptions.ReferenceCheck,
  },
  {
    label: 'Referral',
    value: StageOptions.Referral,
  },
  {
    label: 'Social media',
    value: StageOptions.SocialMedia,
  },
  {
    label: 'Workflow',
    value: StageOptions.Workflow,
  },
  {
    label: 'Workforce',
    value: StageOptions.Workforce,
  },
];

export const marketOptions = [
  {
    label: 'Any market',
    value: MarketOptions.AnyMarket,
  },
  {
    label: 'Sweden',
    value: MarketOptions.Sweden,
  },
  {
    label: 'Norway',
    value: MarketOptions.Norway,
  },
  {
    label: 'Denmark',
    value: MarketOptions.Denmark,
  },
  {
    label: 'Finland',
    value: MarketOptions.Finland,
  },
  {
    label: 'Global',
    value: MarketOptions.Global,
  },
];

export const integrationOptions = [
  {
    label: 'All apps',
    value: IntegrationOptions.AllApps,
  },
  {
    label: 'ReachMee',
    value: IntegrationOptions.ReachMee,
  },
  {
    label: 'Talent Recruiter',
    value: IntegrationOptions.TalentRecruiter,
  },
  {
    label: 'Webcruiter',
    value: IntegrationOptions.Webcruiter,
  },
  {
    label: 'Weekli',
    value: IntegrationOptions.Weekli,
  },
  {
    label: 'irecommend',
    value: IntegrationOptions.Irecommend,
  },
  {
    label: 'Talmundo',
    value: IntegrationOptions.Talmundo,
  },
  {
    label: 'Talent Manager',
    value: IntegrationOptions.TalentManager,
  },
  {
    label: 'Ontame',
    value: IntegrationOptions.Ontame,
  },
];

export const ExternalProductIds: Record<TalentechExternalIds, string> = {
  [TalentechExternalIds.TalentRecruiter]: 'Talent Recruiter',
  [TalentechExternalIds.TalentManager]: 'Talent Manager',
  [TalentechExternalIds.TalentOnboarding]: 'Talent Onboarding',
  [TalentechExternalIds.ReachMee]: 'ReachMee',
  [TalentechExternalIds.Webcruiter]: 'Webcruiter',
  [TalentechExternalIds.Talmundo]: 'Talmundo',
  [TalentechExternalIds.Weekli]: 'Weekli',
  [TalentechExternalIds.irecommend]: 'iRecommend',
  [TalentechExternalIds.PeopleCore]: 'People Core',
  [TalentechExternalIds.Ontame]: 'Ontame',
  [TalentechExternalIds.Marketplace]: 'Marketplace',
  [TalentechExternalIds.TalentechStart]: 'Talentech Start',
};

export const groupByOptions = [
  { label: 'Category', value: GroupByOptions.TalentJourney },
  { label: 'Publisher', value: GroupByOptions.Publisher },
];

export const hubSpotFormModalOpenedEvent: string = 'Opened HubSpot form modal';
export const hubSpotFormSubmittedEvent: string =
  'Submitted HubSpot form successfully';
export const hubSpotFormModalClosedEvent: string =
  'HubSpot form submittion cancelled';

export const shortDescriptionMaxLength: number = 150;

export const HUBSPOT_PORTAL_ID = '2034935';
export const HUBSPOT_FORM_BASE_URL =
  'https://api.hsforms.com/submissions/v3/integration/submit';
export const PROD_HUBSPOT_FORM_ID = '17727b02-c6d5-48b4-8c39-174921e6dfed';
export const TEST_AND_DEV_HUBSPOT_FORM_ID =
  'ee0fe95f-e556-45b6-a477-51b9e29fcafe';

export const PROD_APP_INSIGHTS_INSTRUMENTATION_KEY =
  '3cf6253a-d839-4c04-9dc8-a3ba24737b08';

export const PROD_MARKETPLACE_URL: string = 'https://marketplace.talentech.com';

export const PROD_TALENTECH_ID_URL: string = 'https://login.talentech.io';
export const TEST_TALENTECH_ID_URL: string = 'https://test-login.talentech.io';

export const PROD_PEOPLE_CORE_URL: string = 'https://peoplecore.talentech.io';
export const TEST_PEOPLE_CORE_URL: string =
  'https://test-peoplecore.talentech.io';

export const TEST_TALENTECH_ADMIN_URL: string =
  'https://test-admin.talentech.io';
export const PROD_TALENTECH_ADMIN_URL: string = 'https://admin.talentech.io';
