import { Icon, TalentechIcons } from '@talentech/components';
import {
  resetSearchQuery,
  updateSearchQuery,
} from '../../utils/features/filters/filterSlice';
import { useAppDispatch } from '../../utils/features/hooks';

interface Props {
  searchBarContents: string;
}

const Search = ({ searchBarContents }: Props) => {
  const dispatch = useAppDispatch();

  return (
    <div className="input-group mb-2">
      <div className="input-group-text" id="exampleFormControlInput1Div">
        <Icon
          icon={TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_SEARCH_SEARCH}
        />
      </div>
      <input
        onChange={(e) => dispatch(updateSearchQuery(e.target.value))}
        type="email"
        className="form-control"
        id="exampleFormControlInput1"
        placeholder={'Search for apps & integrations'}
        value={searchBarContents}
      />
      <button
        aria-label="Reset search"
        className="btn btn-outline-secondary border-start-0"
        type="button"
        id="button-addon1"
        onClick={() => dispatch(resetSearchQuery())}
      >
        <Icon
          icon={TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_REMOVE_ADD_REMOVE}
        />
      </button>
    </div>
  );
};
export default Search;
