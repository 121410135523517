import React from 'react';
import { Toast } from '@talentech/components';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../utils/features/hooks';
import { clearToaster } from '../../utils/features/notifications/notificationSlice';
import { StyledNotificationWrapper } from '../../utils/styles';

export const Toaster = () => {
  const dispatch = useAppDispatch();
  const toasterState = useAppSelector((state) => state.notifications);

  const toastTimeout = 5000;

  useEffect(() => {
    toasterState.showToast &&
      setTimeout(() => {
        dispatch(clearToaster());
      }, toastTimeout);
  }, [toasterState, dispatch]);

  return (
    <StyledNotificationWrapper>
      <Toast color={toasterState.color} showToast={toasterState.showToast}>
        {toasterState.message}
      </Toast>
    </StyledNotificationWrapper>
  );
};
