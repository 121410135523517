import { SplashScreen, TalentechSvgImages } from '@talentech/components';
import { Route, Switch } from 'react-router-dom';
import AppDetails from '../pages/appdetails/AppDetails';
import ErrorPage from '../pages/error/ErrorPage';
import HomePage from '../pages/home/HomePage';
import { ActivateSandboxEnvironment } from '../pages/productExperience/ActivateSandboxEnvironment';
import RequestSandbox from '../pages/productExperience/RequestSandbox';
import { SelectProductWidget } from '../pages/productExperience/SelectProduct';
import { SetupCompleted } from '../pages/productExperience/SetupCompleted';
import { AppRoutes, removeLastSlash } from '../utils';
import { marketplace } from '../utils/constants';
import { useGetFeatureToggleQuery } from '../utils/features/services/UnleashService';

const Routes = () => {
  const {
    data: isProductExperienceEnabled,
    isLoading: fetchingFeatureToggle,
    isUninitialized,
  } = useGetFeatureToggleQuery({
    featureToggleName: 'marketplace-requestSandbox',
    enabled: false,
  });

  if (!isUninitialized && fetchingFeatureToggle) {
    return (
      <SplashScreen
        title="Loading app..."
        productName={marketplace}
        isFullPage={true}
        image={TalentechSvgImages.SCIENE_TECHNOLOGY_ROCKET}
      />
    );
  }

  return (
    <Switch>
      <Route exact path={AppRoutes.Home}>
        <HomePage />
      </Route>
      <Route path={AppRoutes.Apps}>
        <AppDetails />
      </Route>
      {isProductExperienceEnabled?.enabled && (
        <>
          <Route exact path={AppRoutes.ProductExperience}>
            <SelectProductWidget />
          </Route>
          <Route
            exact
            path={
              removeLastSlash(AppRoutes.ProductExperience) +
              AppRoutes.PeopleCoreSetupCompleted
            }
          >
            <SetupCompleted />
          </Route>
          <Route exact path={AppRoutes.RequestSandbox}>
            <RequestSandbox />
          </Route>
          <Route exact path={AppRoutes.ConfirmSandboxActivationPage}>
            <ActivateSandboxEnvironment />
          </Route>
        </>
      )}
      <Route path={'*'}>
        <ErrorPage />
      </Route>
    </Switch>
  );
};

export default Routes;
