import { TalentechFormFieldTypes } from '@talentech/components';
import { IFormField } from '@talentech/components/build/@types/talentech-forms';

export const formFields: IFormField[] = [
  {
    field: 'firstName',
    label: 'first name',
    type: TalentechFormFieldTypes.TEXT,
    size: {
      md: 6,
    },
  },
  {
    field: 'lastName',
    label: 'last name',
    type: TalentechFormFieldTypes.TEXT,
    size: {
      md: 6,
    },
  },
  {
    field: 'email',
    label: 'Email',
    type: TalentechFormFieldTypes.TEXT,
    size: {
      md: 6,
    },
  },
  {
    field: 'companyName',
    label: 'Company name',
    type: TalentechFormFieldTypes.TEXT,
    size: {
      md: 6,
    },
  },
];
