import { baseApi } from './baseApi';

const provisioningApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    submitUserDetails: builder.mutation<any, any>({
      query(userDetails) {
        return {
          url: '/PeopleCoreProvisioning',
          method: 'POST',
          body: userDetails,
        };
      },
    }),
    getUserDetailsFromCache: builder.query<any, any>({
      query: (contextId) => `/PeopleCoreProvisioning/ConfirmEmail/${contextId}`,
    }),
    provisionProductForTenant: builder.mutation<void, void>({
      query() {
        return {
          url: `/PeopleCoreProvisioning/Provision/people-core`,
          method: 'POST',
        };
      },
    }),
  }),
});

export const {
  useSubmitUserDetailsMutation,
  useGetUserDetailsFromCacheQuery,
  useProvisionProductForTenantMutation,
} = provisioningApi;
