import {
  Button,
  ButtonColors,
  SplashScreen,
  TalentechSvgImages,
} from '@talentech/components';
import React from 'react';
import { Link } from 'react-router-dom';
import { marketplace } from '../../utils/constants';
import { AppRoutes } from '../../utils/enums';

const ErrorPage: React.FC = () => {
  return (
    <SplashScreen
      image={TalentechSvgImages.NOTIFICATIONS_INFORMATION}
      title="Page not found"
      subtitle={'Sorry, we couldn’t find the page you are looking for.'}
      productName={marketplace}
      isFullPage={false}
      customComponent={
        <Link to={AppRoutes.Home}>
          <Button label={'back to marketplace'} color={ButtonColors.PRIMARY} />
        </Link>
      }
    />
  );
};

export default ErrorPage;
