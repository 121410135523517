import { FeatureToggle } from '../../interfaces';
import { baseApi } from './baseApi';

const unleashApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getFeatureToggle: builder.query<FeatureToggle, FeatureToggle>({
      query: ({ featureToggleName, enabled }) =>
        `/featureToggles/${featureToggleName}?defaultValue=${enabled}`,
    }),
  }),
});

export const { useGetFeatureToggleQuery } = unleashApi;
