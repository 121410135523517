import { AuthenticatedUserInfo, UserTenantsResponse } from '../../interfaces';
import { baseApi } from './baseApi';

type AuthenticatedUserInfoResponse = AuthenticatedUserInfo & {
  isAuthenticated?: boolean;
};

const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getUserInfo: builder.query<AuthenticatedUserInfoResponse, void>({
      query: () => '/userInfo',
      transformResponse: (response: AuthenticatedUserInfoResponse) => {
        return Object.keys(response).length !== 0
          ? { ...response, isAuthenticated: true }
          : { ...response, isAuthenticated: false };
      },
    }),
    getUserTenants: builder.query<UserTenantsResponse, void>({
      query: () => '/userInfo/userTenants',
    }),
    getUserApps: builder.query<unknown, void>({
      query: () => `/navigationApi`,
    }),
  }),
});

export const {
  useGetUserInfoQuery,
  useGetUserTenantsQuery,
  useGetUserAppsQuery,
} = userApi;
