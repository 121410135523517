import { TalentechSvgImages, useSvg } from '@talentech/components';
import styled from 'styled-components';

const AppIcon = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 0.25rem;
`;

const AppDescription = styled.div`
  font-size: 0.88rem;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
`;

type AppWidgetProps = {
  name: string;
  description?: React.ReactNode;
  customContent?: React.ReactNode;
  isInstalled?: boolean;
};

export const AppWidget: React.FC<AppWidgetProps> = ({ ...props }) => {
  return (
    <div
      className={`position-relative d-flex h-100 rounded ${
        props.isInstalled ? 'bg-gray-50 opacity-75' : 'bg-white'
      }`}
    >
      <div className="p-4 d-flex w-100 h-100 flex-column flex-nowrap">
        <AppIcon src={useSvg(TalentechSvgImages.PRODUCTS_LIGHT_TALENTECH)} />
        <div className="d-flex">
          <h4 className="card-title placeholder-glow mb-0 mt-3 text-300">
            {props.name}
          </h4>
        </div>
        <AppDescription className="placeholder-glow lead mb-0 text-100">
          {props.description}
        </AppDescription>
        {props.customContent}
      </div>
    </div>
  );
};
