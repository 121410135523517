import {
  ApplicationBar,
  Avatar,
  TalentechAvatarSizes,
  TalentechIcons,
} from '@talentech/components';
import { AppRoutes, removeLastSlash } from '../../utils';
import { getTalentechIdUrl } from '../../utils/envUtils';
import { useGetUserTenantsQuery } from '../../utils/features/services/UserService';
import { AuthenticatedUserInfo } from '../../utils/interfaces';
import { IListGroupItemProps } from '@talentech/components/build/storybook-core/components/ListGroup/ListGroupItem/ListGroupItem';

export const LoggedInApplicationBar = (props: {
  userInfo: AuthenticatedUserInfo;
  toggleSidebar?(): void;
  isCollapsed?: boolean;
}) => {
  const { data } = useGetUserTenantsQuery();

  function handleLogout() {
    window.location.href = window.location.origin + AppRoutes.Logout;
  }

  function handleSwitchTenant(tenantId: string) {
    window.location.href =
      window.location.origin + `/Home/ChangeTenant?tenantId=${tenantId}`;
  }

  const menuItems = [
    {
      id: 'manage account',
      title: 'Manage account',
      advancedProps: {
        contentImage: (
          <Avatar
            outline
            square
            icon={TalentechIcons.REGULAR_01_INTERFACE_ESSENTIALS_SETTING_COG}
            size={TalentechAvatarSizes.LG}
          />
        ),
        subtitle: props.userInfo.email,
      },
      onClick: (e) => {
        e.preventDefault();
        window.open(
          removeLastSlash(getTalentechIdUrl(window.location.origin)) +
            '/profile',
          '_blank',
        );
      },
    },
    {
      advancedProps: {
        subtitle: 'Sign out of Talentech ID',
        contentImage: (
          <Avatar
            outline
            square
            icon={
              TalentechIcons.BOLD_01_INTERFACE_ESSENTIALS_LOGIN_LOGOUT_LOGOUT
            }
            size={TalentechAvatarSizes.LG}
          />
        ),
      },
      title: 'Sign out',
      onClick: () => handleLogout(),
    },
  ] as IListGroupItemProps[];

  if (data?.tenants && data.tenants.length > 1) {
    menuItems.unshift({
      id: 'switch workplace',
      title: 'Switch workspace',
      onClick: (e) => e.preventDefault(),
      dropdownItem: true,
      advancedProps: {
        subtitle: props.userInfo.workspaceName,
        contentImage: (
          <Avatar
            outline
            icon={
              TalentechIcons.BOLD_17_USERS_GEOMETRIC_FULL_BODY_SINGLE_USER_NEUTRAL_SINGLE_NEUTRAL_ROTATE
            }
            size={TalentechAvatarSizes.LG}
          />
        ),
      },
      submenu: data.tenants.map((tenant) => ({
        icon: TalentechIcons.BOLD_01_INTERFACE_ESSENTIALS_ALERTS_PLUS,
        label: tenant.name,
        active: tenant.name === props.userInfo?.workspaceName ? true : false,
        onClick: (e) => {
          e.preventDefault();
          handleSwitchTenant(tenant.id);
        },
      })),
    });
  }

  return (
    <ApplicationBar
      key={'applicationBar'}
      toolboxItems={[]}
      firstName={props.userInfo.firstName ?? props.userInfo.email}
      lastName={props.userInfo.lastName ?? props.userInfo.email}
      activeWorkspace={props.userInfo.workspaceName}
      avatarUrl={props.userInfo.profilePictureUrl}
      menu={menuItems}
      toggleSidebar={props.toggleSidebar}
    />
  );
};

export default LoggedInApplicationBar;
