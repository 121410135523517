import { Select } from '@talentech/components';
import { stageOptions } from '../../utils/constants';
import { setSelectedStage } from '../../utils/features/filters/filterSlice';
import { useAppDispatch } from '../../utils/features/hooks';
import { SectionTitle } from '../../utils/styles';
import { Wrapper } from './StyledWrapper';

interface Props {
  stageSelectChoice: string;
}

const FilterByStage = ({ stageSelectChoice }: Props) => {
  const dispatch = useAppDispatch();
  const selectedStage = stageOptions.find(
    (stage) => stage.value === stageSelectChoice,
  );
  return (
    <Wrapper>
      <SectionTitle size={'small'}>CATEGORY</SectionTitle>
      <Select
        aria-label="Category"
        // @ts-ignore
        onChange={(selection: { label: string; value: string }) => {
          if (selection.value !== stageSelectChoice)
            dispatch(setSelectedStage(selection.value));
        }}
        value={selectedStage}
        options={stageOptions}
      />
    </Wrapper>
  );
};
export default FilterByStage;
