import { Typography } from '@talentech/components';
import styled from 'styled-components';

export const TitleSection = styled.div<{ isSmall: boolean }>`
  padding: ${({ isSmall }) => (isSmall ? '1rem' : '1.5rem')};
  p {
    margin: 0.75rem auto auto auto;
    font-size: 1.5rem;
  }
`;

export const BodySection = styled.div<{ isSmall: boolean }>`
  padding: ${({ isSmall }) => (isSmall ? '1rem' : '1.5rem 1.5rem 1rem 1.5rem')};
`;

export const FooterSection = styled(TitleSection)`
  padding: ${({ isSmall }) => (isSmall ? '1rem' : '1.5rem')};
`;

export const Title = styled(Typography)`
  font-weight: 600;
  font-size: 0.75rem;
  color: #444450;
`;
export const Subtitle = styled(Title)`
  font-size: 0.875rem;
  color: #26253e;
`;

export const TagBoxWrapper = styled.div`
  margin-bottom: 1.25rem;
`;
