import {
  Alert,
  Box,
  Forms,
  Grid,
  PageTitle,
  Row,
  TalentechColors,
} from '@talentech/components';
import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../utils';
import { useAppDispatch } from '../../utils/features/hooks';
import { displayToaster } from '../../utils/features/notifications/notificationSlice';
import { useSubmitUserDetailsMutation } from '../../utils/features/services/ProvisioningService';
import { formFields } from '../../utils/formfields/RequestSandboxFormFields';
import { ProductExperienceRegistrationSchema } from '../../utils/formfields/validationSchemas';

export type CustomerDetails = {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
};

const RequestPeopleCoreSandbox = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const requestSandboxFormRef = useRef(null);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const [
    submitUserDetails,
    { isLoading: isSubmitting, isSuccess, isError, isUninitialized },
  ] = useSubmitUserDetailsMutation();

  useEffect(() => {
    if (!isUninitialized && !isSubmitting) {
      if (isError) {
        dispatch(
          displayToaster({
            showToast: true,
            color: TalentechColors.DANGER,
            message:
              'There was an issue submitting the form. Please try again later.',
          }),
        );
      }
      if (isSuccess) {
        // @ts-ignore
        requestSandboxFormRef.current?.resetForm();
        setShowAlert(true);
      }
    }
  }, [isSuccess, isError, dispatch, isUninitialized, isSubmitting]);

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            label: 'Home',
            onClick: (e) => {
              e.preventDefault();
              history.push({ pathname: AppRoutes.Home });
            },
          },
          {
            label: 'Product experience',
            onClick: (e) => {
              e.preventDefault();
              history.push({ pathname: AppRoutes.ProductExperience });
            },
          },
          {
            label: 'People Core',
            isActive: true,
          },
        ]}
        title="Get started with People Core"
      />
      <Grid className="mt-3" container>
        <Row>
          <Alert
            color={TalentechColors.SUCCESS}
            message={
              'Your form was submitted successfully and you are now one step closer to accessing your sandbox environment. Please confirm your email address by clicking the "verify email" button in the email we sent you.'
            }
            isOpen={showAlert}
            dismissible={true}
            title={''}
            onClose={() => setShowAlert(!showAlert)}
          />
          <Box paper rounded spacing={4}>
            <Forms.Form
              reference={requestSandboxFormRef}
              validationSchema={ProductExperienceRegistrationSchema}
              formFields={{
                section:
                  'Register an account to get one step closer to accessing your People Core trial',
                fields: formFields,
                id: 'requestSandbox',
              }}
              submitLabel="Submit"
              handleSubmit={async (values) => {
                submitUserDetails(values.requestSandbox);
              }}
              handleReset={() => null}
            />
          </Box>
        </Row>
      </Grid>
    </>
  );
};

export default RequestPeopleCoreSandbox;
