import {
  LayoutGrid,
  SplashScreen,
  TalentechSvgImages,
} from '@talentech/components';
import { LoggedInApplicationBar } from '../components/applicationBars/LoggedInApplicationBar';
import LoggedOutLayout from '../components/applicationBars/LoggedOutLayout';
import SidebarComponent from '../components/sidebar/Sidebar';
import { marketplace } from '../utils/constants';
import { useGetFeatureToggleQuery } from '../utils/features/services/UnleashService';
import { useGetUserInfoQuery } from '../utils/features/services/UserService';

import Routes from './Routes';

const Layout = (): JSX.Element => {
  const { data: userInfo, isLoading: loadingUserInfo } = useGetUserInfoQuery();

  const {
    data: isProductExperienceEnabled,
    isLoading: loadingFeatureToggleStatus,
  } = useGetFeatureToggleQuery({
    featureToggleName: 'marketplace-requestSandbox',
    enabled: false,
  });

  if (loadingUserInfo && loadingFeatureToggleStatus) {
    return (
      <SplashScreen
        title="Loading app..."
        productName={marketplace}
        isFullPage={true}
        image={TalentechSvgImages.SCIENE_TECHNOLOGY_ROCKET}
      />
    );
  }

  return (
    <>
      {userInfo?.isAuthenticated ? (
        <LayoutGrid
          sidebar={<SidebarComponent />}
          header={<LoggedInApplicationBar userInfo={userInfo} />}
          children={<Routes />}
        />
      ) : (
        <LoggedOutLayout
          isProductExperienceEnabled={
            isProductExperienceEnabled?.enabled ?? false
          }
          children={<Routes />}
        />
      )}
    </>
  );
};

export default Layout;
