import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    prepareHeaders: (headers: Headers) => {
      headers.set('X-CSRF', '1');
      return headers;
    },
  }),
  endpoints: () => ({}),
});
