import {
  breakpoint,
  BreakpointType,
  Grid,
  Row,
  SplashScreen,
  TalentechSvgImages,
  Typography,
} from '@talentech/components';
import { useMediaQuery } from 'beautiful-react-hooks';
import React, { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { AppGroup } from '../../components/appGroup/AppGroup';
import FilterByIntegration from '../../components/filter/FilterByIntegration';
import FilterByMarket from '../../components/filter/FilterByMarket';
import FilterByStage from '../../components/filter/FilterByStage';
import { GroupByPublisher } from '../../components/filter/GroupByPublisher';
import Hero from '../../components/hero/Hero';
import Search from '../../components/search/Search';
import { marketplace, stageOptions, talentech } from '../../utils/constants';
import {
  AppRoutes,
  GroupByOptions,
  IntegrationOptions,
  MarketOptions,
  StageOptions,
} from '../../utils/enums';
import { useGetAppsQuery } from '../../utils/features/services/AppsService';
import {
  setIntegratesWith,
  setSelectedMarket,
  setSelectedPublisher,
  setSelectedStage,
  updateSearchQuery,
} from '../../utils/features/filters/filterSlice';
import { useAppDispatch, useAppSelector } from '../../utils/features/hooks';
import HeroImage from '../../utils/images/HeroImage.svg';
import HeroImageMobile from '../../utils/images/HeroImageMobile.svg';
import { AppDetails } from '../../utils/interfaces';
import {
  filterApps,
  filterAppsForTalentJourney,
  isValidGroupByParam,
  isValidIntegratesParam,
  isValidMarketParam,
  isValidStageParam,
  sortByTalentechFirst,
  useUrlSearchParams,
} from './utils';

const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { data, isLoading, isError } = useGetAppsQuery();
  const searchParam = useUrlSearchParams('q');
  const stageParam = useUrlSearchParams('stage');
  const integratesParam = useUrlSearchParams('integratesWith');
  const groupByParam = useUrlSearchParams('groupBy');
  const marketParam = useUrlSearchParams('market');
  const isInitialRender = useRef(false);
  const location = useLocation();
  const history = useHistory();
  const activeFilters = useAppSelector((state) => state.filters);
  const isSmall = useMediaQuery(breakpoint(BreakpointType.MAX, 364));

  const filteredApps = filterApps(
    data ? data : [],
    activeFilters.searchQuery,
    activeFilters.selectedIntegration,
    activeFilters.selectedStage,
    activeFilters.selectedMarket,
  ).sort((a, b) => {
    return a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
  });

  useEffect(() => {
    dispatch(
      updateSearchQuery(
        searchParam !== '' && searchParam !== null
          ? (searchParam as string)
          : '',
      ),
    );

    dispatch(
      setSelectedStage(
        stageParam !== null && isValidStageParam(stageParam)
          ? stageParam
          : StageOptions.FullTalentJourney,
      ),
    );

    dispatch(
      setIntegratesWith(
        integratesParam !== null && isValidIntegratesParam(integratesParam)
          ? integratesParam
          : IntegrationOptions.AllApps,
      ),
    );

    dispatch(
      setSelectedPublisher(
        groupByParam !== null && isValidGroupByParam(groupByParam)
          ? groupByParam
          : GroupByOptions.TalentJourney,
      ),
    );

    dispatch(
      setSelectedMarket(
        marketParam !== null && isValidMarketParam(marketParam)
          ? marketParam
          : MarketOptions.AnyMarket,
      ),
    );
  }, [
    searchParam,
    stageParam,
    integratesParam,
    groupByParam,
    marketParam,
    dispatch,
  ]);

  useEffect(() => {
    const searchParams = {
      ...(activeFilters.searchQuery !== '' && {
        q: activeFilters.searchQuery,
      }),
      ...(activeFilters.selectedStage !== StageOptions.FullTalentJourney && {
        stage: activeFilters.selectedStage,
      }),
      ...(activeFilters.selectedIntegration !== IntegrationOptions.AllApps && {
        integratesWith: activeFilters.selectedIntegration,
      }),
      ...(activeFilters.groupByPublisher !== GroupByOptions.TalentJourney && {
        groupBy: activeFilters.groupByPublisher,
      }),
      ...(activeFilters.selectedMarket !== MarketOptions.AnyMarket && {
        market: activeFilters.selectedMarket,
      }),
    };

    if (isInitialRender.current) {
      history.push({
        pathname: AppRoutes.Home,
        search: new URLSearchParams(searchParams).toString(),
      });
    } else {
      isInitialRender.current = true;
    }
  }, [
    activeFilters.searchQuery,
    activeFilters.selectedStage,
    activeFilters.selectedIntegration,
    activeFilters.groupByPublisher,
    activeFilters.selectedMarket,
    history,
    dispatch,
    location.hash,
  ]);

  return (
    <main>
      <>
        <Grid container className={'py-4'}>
          <Hero
            title={marketplace}
            subtitle={'Integrations that will scale your solution'}
            image={isSmall ? HeroImageMobile : HeroImage}
          />

          <div className={'d-flex flex-column flex-lg-row py-4'}>
            <Typography variant="h2" className="w-100">
              {'Apps & integrations'}
            </Typography>
            <Search searchBarContents={activeFilters.searchQuery} />
          </div>

          <hr className="m-0" />

          <Row className={'py-4'}>
            <FilterByStage stageSelectChoice={activeFilters.selectedStage} />
            <FilterByIntegration
              integratesWithChoice={activeFilters.selectedIntegration}
            />
            <FilterByMarket market={activeFilters.selectedMarket} />
          </Row>

          <hr className="m-0" />

          <div className={'py-4'}>
            <GroupByPublisher
              selectedPublisher={activeFilters.groupByPublisher}
            />
          </div>

          {isLoading && (
            <SplashScreen
              title="Loading apps..."
              productName={marketplace}
              isFullPage={false}
              image={TalentechSvgImages.SCIENE_TECHNOLOGY_ROCKET}
            />
          )}
          {isError && (
            <SplashScreen
              image={TalentechSvgImages.NOTIFICATIONS_INFORMATION}
              title="Error while loading the applications"
              subtitle={
                'We could not fetch the applications, try refreshing the page.'
              }
              productName={marketplace}
              isFullPage={false}
            />
          )}

          {filteredApps.length > 0 ? (
            activeFilters.groupByPublisher === 'talentJourney' ? (
              stageOptions.map((stage, index) => {
                const filteredAppsForTalentJourney: Array<AppDetails> =
                  filterAppsForTalentJourney(
                    sortByTalentechFirst(filteredApps),
                    stage.value,
                  );

                return (
                  <>
                    {filteredAppsForTalentJourney.length > 0 &&
                      (activeFilters.selectedStage ===
                      StageOptions.FullTalentJourney ? (
                        <AppGroup
                          key={index}
                          label={stage.label}
                          labelSize={{ size: 'large' }}
                          apps={filteredAppsForTalentJourney}
                        />
                      ) : (
                        activeFilters.selectedStage === stage.value && (
                          <AppGroup
                            key={index}
                            label={stage.label}
                            labelSize={{ size: 'large' }}
                            apps={filteredAppsForTalentJourney}
                          />
                        )
                      ))}
                  </>
                );
              })
            ) : (
              <>
                {filteredApps.filter(
                  (app: AppDetails) => app.creator === talentech,
                ).length > 0 && (
                  <AppGroup
                    label={'Talentech'}
                    labelSize={{ size: 'large' }}
                    apps={filteredApps.filter(
                      (app) => app.creator === talentech,
                    )}
                  />
                )}

                {filteredApps.filter((app) => app.creator !== talentech)
                  .length > 0 && (
                  <AppGroup
                    label={'Other'}
                    labelSize={{ size: 'large' }}
                    apps={filteredApps.filter(
                      (app) => app.creator !== talentech,
                    )}
                  />
                )}
              </>
            )
          ) : (
            !isLoading &&
            !isError && (
              <SplashScreen
                productName=""
                title={'Nothing here'}
                isFullPage={false}
                subtitle={
                  'Try tweaking the filters, or reach out if you can’t find what you need.'
                }
                image={
                  TalentechSvgImages.MARKETING_ADVERTISING_VIEW_COUNT_MAGNIFY
                }
              />
            )
          )}
        </Grid>
      </>
    </main>
  );
};

export default HomePage;
