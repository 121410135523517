import { TalentechColors, Toast } from '@talentech/components';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
export interface SnackbarProps {
  color: Exclude<
    TalentechColors,
    TalentechColors.PRIMARY | TalentechColors.SECONDARY
  >;
  content: string;
  toggleSnackbar: boolean;
}

const SnackbarStyling = styled.div`
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 1;
`;

const StyledSnackbar: React.FC<SnackbarProps> = (props) => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    setShow(props.toggleSnackbar);
  }, [props]);

  return (
    <>
      {show && props.content && (
        <div className="container">
          <SnackbarStyling>
            <Toast color={props.color} onClose={() => setShow(false)}>
              {props.content}
            </Toast>
          </SnackbarStyling>
        </div>
      )}
    </>
  );
};
export default StyledSnackbar;
