import { talentech } from '../../utils/constants';
import { AppDetails } from '../../utils/interfaces';
import Shield from '../shield/Index';
import HomePageAppCard from './HomePageAppCard';
import { defaultLogo } from '../../utils';

const Cards = (props: { filteredApps: Array<AppDetails> }) => {
  return (
    <>
      {props.filteredApps.map(
        (app) =>
          app.isPublished === true && (
            <HomePageAppCard
              className={'col-12 col-md-6 col-xl-4 col-xxl-3'}
              name={app.name}
              description={app.shortDescription}
              integratesWith={app.integratesWith}
              key={app.name}
              logo={
                <Shield
                  className={'mb-4'}
                  key={app.appIconUrl}
                  logo={app.appIconUrl || defaultLogo}
                  showBorder={
                    !app.appIconUrl ? false : app.creator !== talentech
                  }
                />
              }
              id={app.id}
              urlFriendlyName={app.urlFriendlyName}
            />
          ),
      )}
    </>
  );
};

export default Cards;
