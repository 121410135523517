import { TalentechFormFieldTypes } from '@talentech/components';
import { IFormSection } from '@talentech/components/build/@types/talentech-forms';

export const IntegrationFormFields: IFormSection = {
  id: 'customer',
  section: '',
  fields: [
    {
      field: 'firstname',
      label: 'first name',
      type: TalentechFormFieldTypes.TEXT,
      size: {
        xs: 12,
        sm: 6,
      },
    },
    {
      field: 'lastname',
      label: 'last name',
      type: TalentechFormFieldTypes.TEXT,
      size: {
        xs: 12,
        sm: 6,
      },
    },
    {
      field: 'email',
      label: 'Business Email',
      type: TalentechFormFieldTypes.TEXT,
      size: {
        xs: 12,
      },
    },
    {
      field: 'partner_integration_reason',
      label: 'Questions or comments (Optional)',
      type: TalentechFormFieldTypes.TEXTAREA,
      size: {
        xs: 12,
      },
    },
  ],
};
