import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GroupByOptions,
  IntegrationOptions,
  MarketOptions,
  StageOptions,
} from '../../enums';
import { FiltersState } from '../../interfaces';

const initialState: FiltersState = {
  searchQuery: '',
  selectedStage: StageOptions.FullTalentJourney,
  selectedIntegration: IntegrationOptions.AllApps,
  selectedMarket: MarketOptions.AnyMarket,
  groupByPublisher: GroupByOptions.TalentJourney,
};

const filtersReducer = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateSearchQuery: (state, action: PayloadAction<string>) => {
      return { ...state, searchQuery: action.payload };
    },
    resetSearchQuery(state) {
      return { ...state, searchQuery: '' };
    },
    setSelectedPublisher(state, action: PayloadAction<string>) {
      return { ...state, groupByPublisher: action.payload };
    },
    setSelectedMarket(state, action: PayloadAction<string>) {
      return { ...state, selectedMarket: action.payload };
    },
    setIntegratesWith(state, action: PayloadAction<string>) {
      return { ...state, selectedIntegration: action.payload };
    },
    setSelectedStage(state, action: PayloadAction<string>) {
      return { ...state, selectedStage: action.payload };
    },
    resetSearchAndFilters() {
      return initialState;
    },
  },
});

export const {
  updateSearchQuery,
  resetSearchQuery,
  setSelectedMarket,
  setSelectedPublisher,
  setIntegratesWith,
  setSelectedStage,
  resetSearchAndFilters,
} = filtersReducer.actions;

export default filtersReducer.reducer;
