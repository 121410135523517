import {
  Accordion,
  Box,
  Grid,
  Icon,
  TalentechIcons,
} from '@talentech/components';
import styled from 'styled-components';
import { SanitizedDirtyHtmlContent } from '../../utils';
import {
  AdditionalContent,
  AppDetails,
  AdditionalContentItems,
} from '../../utils/interfaces';
import { Title } from './CardStylings';

const ShortDescription = styled(Title)`
  font-size: 1.125rem;
  line-height: 2rem;
`;

const Description = styled(SanitizedDirtyHtmlContent)`
  font-size: 1rem;
  line-height: 1.5rem;
`;

const AccordionSectionHeader = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
`;

const AppDescription = (props: Partial<AppDetails>) => {
  const mappedAdditionalContent =
    props.additionalContent?.map(
      (section: AdditionalContentItems, index: number) => ({
        id: index.toString(),
        title: section.title,
        content: section.content.map(
          (content: AdditionalContent, index: number) => (
            <div key={index}>
              {content.header && (
                <AccordionSectionHeader
                  className={'mb-3 text-300 fw-semi-bold'}
                >
                  {content.header}
                </AccordionSectionHeader>
              )}
              {section.title === 'Stories about this app' && !content.header ? (
                <div className="d-flex align-items-center py-2 fw-semi-bold">
                  <Icon
                    className="me-1"
                    icon={
                      TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_LINK_UNLINK_HYPERLINK_3
                    }
                  />
                  <SanitizedDirtyHtmlContent
                    dirtyContent={content.body}
                    allowedTags={['a', 'p', 'ol', 'li', 'br']}
                    className="text-300 border-bottom"
                  />
                </div>
              ) : (
                <SanitizedDirtyHtmlContent
                  dirtyContent={content.body}
                  allowedTags={['a', 'p', 'ol', 'li', 'br']}
                  className="text-300 fw-normal"
                />
              )}
            </div>
          ),
        ),
      }),
    ) ?? [];

  return (
    <Box paper rounded dropShadow className={'h-100'}>
      <Grid container className={'p-4'}>
        {props.detailsPageImageUrl && (
          <img
            className="pb-4"
            src={props.detailsPageImageUrl}
            alt={'Product'}
            style={{
              maxWidth: '100%',
              borderRadius: '0.25rem',
              aspectRatio: '16/9',
            }}
          />
        )}
        {props.shortDescription && (
          <ShortDescription className="fw-semi-bold">
            {props.shortDescription}
          </ShortDescription>
        )}
        {props.description && (
          <Description
            className={'text-200 fw-normal'}
            dirtyContent={props.description}
            allowedTags={['p', 'ul', 'li', 'b', 'br', 'a', 'u', 'strong']}
          />
        )}
        {props.additionalContent && (
          <div className="mt-4">
            <Accordion
              accordionItems={mappedAdditionalContent}
              collapseMultiple
            />
          </div>
        )}
      </Grid>
    </Box>
  );
};

export default AppDescription;
