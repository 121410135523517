import { baseApi } from './baseApi';

type ProductResponse = {
  talentechExternalId: string;
};

const tenantApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTenantProducts: builder.query<ProductResponse[], void>({
      query: () => `/tenant/products`,
    }),
  }),
});

export const { useGetTenantProductsQuery } = tenantApi;
