import { Select } from '@talentech/components';
import { integrationOptions } from '../../utils/constants';
import { setIntegratesWith } from '../../utils/features/filters/filterSlice';
import { useAppDispatch } from '../../utils/features/hooks';
import { SectionTitle } from '../../utils/styles';
import { Wrapper } from './StyledWrapper';

interface Props {
  integratesWithChoice: string;
}

const FilterByMarket = ({ integratesWithChoice }: Props) => {
  const dispatch = useAppDispatch();
  const selectedIntegration = integrationOptions.find(
    (integration) => integration.value === integratesWithChoice,
  );
  return (
    <Wrapper>
      <SectionTitle size={'small'}>WORKS WITH</SectionTitle>
      <Select
        aria-label="Works with"
        // @ts-ignore
        onChange={(selection: { label: string; value: string }) => {
          if (selection.value !== integratesWithChoice) {
            dispatch(setIntegratesWith(selection.value));
          }
        }}
        value={selectedIntegration}
        options={integrationOptions}
      />
    </Wrapper>
  );
};
export default FilterByMarket;
