import { useLocation } from 'react-router-dom';
import {
  groupByOptions,
  marketOptions,
  stageOptions,
  talentech,
} from '../../utils/constants';
import {
  IntegrationOptions,
  MarketOptions,
  StageOptions,
} from '../../utils/enums';
import { AppDetails } from '../../utils/interfaces';

export const filterApps = (
  apps: Array<AppDetails>,
  searchBarContents: string,
  integratesWithChoice: string,
  stageSelectChoice: string,
  marketChoice: string,
) => {
  const filterBySearch = (app: AppDetails) => {
    const searchEmpty = searchBarContents === '';
    const foundInSearch = app.name
      .toLowerCase()
      .includes(searchBarContents.toLowerCase());
    return searchEmpty ? true : foundInSearch;
  };
  const filterByStage = (app: AppDetails) => {
    const isDefaultStage = stageSelectChoice === StageOptions.FullTalentJourney;
    const matchesStage = app.journeyStep.includes(stageSelectChoice);
    return isDefaultStage ? true : matchesStage;
  };

  const filterByIntegratesWith = (app: AppDetails) => {
    const isDefaultIntegratedWith =
      integratesWithChoice === IntegrationOptions.AllApps;
    const matchintegratesWith =
      app.integratesWith.includes(integratesWithChoice);
    return isDefaultIntegratedWith ? true : matchintegratesWith;
  };

  const filterByMarket = (app: AppDetails) => {
    const isDefaultMarket = marketChoice === MarketOptions.AnyMarket;
    const matchesMarket = app.markets.includes(marketChoice);
    return isDefaultMarket ? true : matchesMarket;
  };

  return apps
    .filter((app) => filterBySearch(app))
    .filter((app) => filterByStage(app))
    .filter((app) => filterByIntegratesWith(app))
    .filter((app) => filterByMarket(app));
};

export const useUrlSearchParams = (param: string) => {
  return new URLSearchParams(useLocation().search).get(param);
};

export const isValidStageParam = (stageParam: string) => {
  return stageOptions.map((i) => i.value === stageParam).includes(true);
};

export const isValidIntegratesParam = (integratesParam: string) => {
  const values: Array<string> = Object.values(IntegrationOptions);
  return values.includes(integratesParam);
};

export const isValidGroupByParam = (groupByParam: string) => {
  return groupByOptions
    .map((buttonData) => buttonData.value === groupByParam)
    .includes(true);
};

export const isValidMarketParam = (marketParam: string) => {
  return marketOptions.map((i) => i.value === marketParam).includes(true);
};

export const sortByTalentechFirst = (filteredApps: Array<AppDetails>) => {
  const talentechApps = filteredApps.filter((app) => app.creator === talentech);
  const nonTalentechApps = filteredApps.filter(
    (app) => app.creator !== talentech,
  );

  return [...talentechApps, ...nonTalentechApps];
};

export const filterAppsForTalentJourney = (
  filteredApps: Array<AppDetails>,
  journeyStep: string,
) => {
  return filteredApps.filter((app: AppDetails) =>
    app.journeyStep.includes(journeyStep),
  );
};
