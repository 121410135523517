export enum AppRoutes {
  Home = '/',
  Apps = '/apps/:id-:name',
  ProductExperience = '/product-experience/',
  RequestSandbox = '/request-sandbox/:id',
  ConfirmSandboxActivationPage = '/PeopleCoreProvisioning/ActivateSandbox',
  Login = '/bff/login',
  Logout = '/bff/logout',
  PeopleCoreSetupCompleted = '/people-core/setupcompleted',
}

export enum IntegrationOptions {
  AllApps = 'allApps',
  ReachMee = 'reachMee',
  TalentRecruiter = 'talentRecruiter',
  Webcruiter = 'webcruiter',
  Irecommend = 'irecommend',
  Weekli = 'weekli',
  Talmundo = 'talmundo',
  TalentManager = 'talentManager',
  Ontame = 'ontame',
}

export enum MarketOptions {
  AnyMarket = 'AnyMarket',
  Sweden = 'Sweden',
  Norway = 'Norway',
  Denmark = 'Denmark',
  Finland = 'Finland',
  Global = 'Global',
}

export enum StageOptions {
  FullTalentJourney = 'fullTalentJourney',
  Absence = 'absence',
  Analytics = 'analytics',
  Archiving = 'archiving',
  Assessment = 'assessment',
  ATS = 'ats',
  BackgroundCheck = 'backgroundCheck',
  CandidateFeedback = 'candidateFeedback',
  Chatbot = 'chatbot',
  CoreHR = 'coreHR',
  EasyApply = 'easyApply',
  EmployeeFeedback = 'employeeFeedback',
  ESigning = 'eSigning',
  JobBoard = 'jobBoard',
  OnOffboarding = 'onOffBoarding',
  Other = 'other',
  Payroll = 'payroll',
  ReferenceCheck = 'referenceCheck',
  Referral = 'referral',
  SocialMedia = 'socialMedia',
  Workflow = 'workflow',
  Workforce = 'workforce',
}

export enum GroupByOptions {
  TalentJourney = 'talentJourney',
  Publisher = 'publisher',
}

export enum TalentechExternalIds {
  TalentRecruiter = 'c28db144-e06d-4b17-a543-02bc5f5d44f3',
  TalentManager = '86f8d7cb-3f45-40db-a0be-920beac48aa6',
  TalentOnboarding = 'b814a44b-16aa-439a-88bd-ad6580b586dc',
  ReachMee = 'e4e77ad9-a385-492c-9f6f-73fa5a342e45',
  Webcruiter = '45ee0d96-9a1c-4ff4-a20b-a30f65edee41',
  Talmundo = '3e0bbbbb-a762-4018-9edc-95f763316b9c',
  Weekli = '8328bf79-6183-431c-a6a6-7d433f25d226',
  irecommend = 'ea78ff86-c81f-4097-93d9-1cf910931c9a',
  PeopleCore = '891ce8fc-2b0d-42ba-97f1-9262cb4c006b',
  Marketplace = '6d908fb3-2b12-4214-9cd7-831fdfe9cbd3',
  Ontame = '29176d59-19ac-4c68-85ef-389838411942',
  TalentechStart = '88bdee22-df9e-4f70-8b61-9efc0c7aa25a',
}
