import {
  Box,
  breakpoint,
  BreakpointType,
  Button,
  ButtonColors,
  TalentechResponsiveBreakPoints,
  Typography,
} from '@talentech/components';
import { useMediaQuery } from 'beautiful-react-hooks';
import { integrationOptions } from '../../utils/constants';
import { DottedDivider, TagBox } from '../../utils/styles';
import Shield from '../shield/Index';
import {
  BodySection,
  FooterSection,
  Subtitle,
  TagBoxWrapper,
  Title,
  TitleSection,
} from './CardStylings';

type AppFactProps = {
  logo: any;
  showBorder: boolean;
  label: string;
  appName: string;
  markets: Array<string>;
  integratesWith: Array<string>;
  languages: Array<string>;
  onClick: () => void;
};

const AppFacts = (props: AppFactProps) => {
  const isSmall = useMediaQuery(
    breakpoint(BreakpointType.MAX, TalentechResponsiveBreakPoints.SM),
  );

  const integrations = integrationOptions.filter((productRegister) =>
    props.integratesWith.includes(productRegister.value),
  );

  return (
    <Box rounded paper dropShadow>
      <TitleSection isSmall={isSmall}>
        <Shield logo={props.logo} showBorder={props.showBorder} />
        <Typography className={'fw-bold text-break'}>
          {props.appName}
        </Typography>
      </TitleSection>
      <hr className="m-0" />
      <BodySection isSmall={isSmall}>
        {props.integratesWith.length !== 0 && (
          <>
            <Title className={'mb-4'}>INTEGRATES WITH</Title>
            <TagBoxWrapper className={'d-flex flex-wrap mt-auto'}>
              {integrations.map((integratesWith, index) => (
                <TagBox className={'text-uppercase text-300'} key={index}>
                  <Typography variant="p" className="m-0">
                    {integratesWith.label}
                  </Typography>
                </TagBox>
              ))}
            </TagBoxWrapper>
            <DottedDivider className={'mb-3'} />
          </>
        )}

        <Title className={'mb-2'}>MARKET</Title>
        {props.markets && (
          <Subtitle className={'mb-3'}>
            {props.markets.includes('Global')
              ? 'Global'
              : Object.entries(props.markets).map(
                  ([key, value], index, arr) => {
                    return index === arr.length - 1 ? value : `${value}, `;
                  },
                )}
          </Subtitle>
        )}

        <Title className={'mb-2'}>LANGUAGES</Title>
        {props.languages && (
          <Subtitle>
            {Object.entries(props.languages).map(([key, value], index, arr) => {
              return index === arr.length - 1
                ? value.toUpperCase()
                : `${value.toUpperCase()}, `;
            })}
          </Subtitle>
        )}
      </BodySection>
      <hr className="m-0" />
      <FooterSection isSmall={isSmall}>
        <Button
          block
          label={props.label}
          onClick={props.onClick}
          color={ButtonColors.PRIMARY}
        />
      </FooterSection>
    </Box>
  );
};

export default AppFacts;
