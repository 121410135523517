import { Sidebar, TalentechIcons } from '@talentech/components';
import { useHistory } from 'react-router-dom';
import { AppRoutes, openUrlInNewTab } from '../../utils';
import { marketplace } from '../../utils/constants';
import { resetSearchAndFilters } from '../../utils/features/filters/filterSlice';
import { useAppDispatch } from '../../utils/features/hooks';
import { useGetFeatureToggleQuery } from '../../utils/features/services/UnleashService';
import {
  useGetUserAppsQuery,
  useGetUserInfoQuery,
} from '../../utils/features/services/UserService';
import { ISidebarMenuItem } from '@talentech/components/build/storybook-core/components/Sidebar/SidebarTypes.d.d';

const SidebarComponent = (props: any) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { data: userInfo } = useGetUserInfoQuery();
  const userApps = useGetUserAppsQuery(undefined, {
    skip: userInfo ? !userInfo.isAuthenticated : true,
  });

  const { data: isProductExperienceEnabled } = useGetFeatureToggleQuery({
    featureToggleName: 'marketplace-requestSandbox',
    enabled: false,
  });

  const menuItems: ISidebarMenuItem[] = [
    {
      id: 'Home',
      icon: TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_HOME_HOUSE_1,
      label: 'Home',
      onClick: () => {
        dispatch(resetSearchAndFilters());
        history.push(AppRoutes.Home);
      },
    },
    {
      id: 'Become a partner',
      icon: TalentechIcons.LIGHT_17_USERS_GEOMERTRIC_CLOSE_UP_MULTIPLE_USERS_ACTIONS_MULTIPLE_ACTIONS_HEART,
      label: 'Become a partner',
      onClick: () => {
        openUrlInNewTab(
          'https://content.talentech.com/join-our-partner-network',
        );
      },
    },
    {
      id: 'Support',
      icon: TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_HELP_HEADPHONES_CUSTOMER_SUPPORT,
      label: 'Support',
      onClick: () => {
        openUrlInNewTab(
          'https://talentech-support.freshdesk.com/en/support/tickets/new',
        );
      },
    },
  ];

  return (
    <Sidebar
      appSwitcher={userApps.data ?? []}
      activeLanguage="en"
      activeApp={{
        id: '6d908fb3-2b12-4214-9cd7-831fdfe9cbd3',
        name: 'Marketplace',
        disclaimer: 'by Talentech',
        appIconUrl:
          'https://storybook.talentech.io/images/svg/products/light/talentech.svg',
      }}
      menu={[
        {
          sectionName: marketplace,
          menuItems: isProductExperienceEnabled?.enabled
            ? [
                ...menuItems,
                {
                  id: 'Product experience',
                  label: 'Product experience',
                  icon: TalentechIcons.LIGHT_06_BUSINESS_PRODUCTS_BUSINESS_USER_NETWORK,
                  onClick: () => {
                    history.push(AppRoutes.ProductExperience);
                  },
                },
              ]
            : menuItems,
        },
      ]}
      {...props}
    />
  );
};

export default SidebarComponent;
