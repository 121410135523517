import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TalentechColors } from '@talentech/components';
import { IToastProps } from '@talentech/components/build/storybook-core/components/Toast/Toast';

interface NotificationProps extends IToastProps {
  showToast: boolean;
  message: string;
  color: Exclude<
    TalentechColors,
    TalentechColors.PRIMARY | TalentechColors.SECONDARY
  >;
}

const initialState: NotificationProps = {
  showToast: false,
  message: '',
  color: TalentechColors.SUCCESS,
};

const notificationsReducer = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    displayToaster: (state, action: PayloadAction<NotificationProps>) => {
      state.color = action.payload.color;
      state.showToast = action.payload.showToast;
      state.onClose = action.payload.onClose;
      state.message = action.payload.message;
    },
    clearToaster: () => {
      return initialState;
    },
  },
});

export const { displayToaster, clearToaster } = notificationsReducer.actions;

export default notificationsReducer.reducer;
