import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
  Alert,
  Button,
  ButtonColors,
  Grid,
  ModalForm,
  PageTitle,
  SplashScreen,
  TalentechColors,
  TalentechSvgImages,
} from '@talentech/components';
import Axios, { AxiosError, AxiosResponse } from 'axios';
import DOMPurify from 'dompurify';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import AppDescription from '../../components/factCard/AppDescription';
import AppFacts from '../../components/factCard/AppFacts';
import StyledSnackbar from '../../components/snackbar/StyledSnackbar';
import { useGetAppsQuery } from '../../utils/features/services/AppsService';
import {
  AppRoutes,
  CustomerDetailsFormat,
  defaultLogo,
  HubSpotFormFormat,
  hubSpotFormModalClosedEvent,
  hubSpotFormModalOpenedEvent,
  hubSpotFormSubmittedEvent,
  IntegrationFormFields,
  Page,
  talentech,
  userSchema,
} from '../../utils';
import { resetSearchAndFilters } from '../../utils/features/filters/filterSlice';
import { useAppDispatch } from '../../utils/features/hooks';

import {
  getHubspotFormID,
  getHubspotPortalID,
  getHubspotFormBaseURL,
} from '../../utils/envUtils';
import { marketplace } from '../../utils/constants';

const hubSpotFormUrl: string = `${getHubspotFormBaseURL()}/${getHubspotPortalID()}/${getHubspotFormID(
  window.location.origin,
)}`;

const StyledGrid = styled(Grid).attrs({ className: 'pt-4' })`
  margin-bottom: 4rem;
`;

const AppDetails = () => {
  const ai = useAppInsightsContext();
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { selectedApp, isUninitialized, isLoading, isError } = useGetAppsQuery(
    undefined,
    {
      selectFromResult: ({ data, isUninitialized, isLoading, isError }) => ({
        selectedApp: data?.find((app) => app.id === id),
        isUninitialized,
        isLoading,
        isError,
      }),
    },
  );
  const { search } = useLocation();
  const history = useHistory();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [hubSpotResponse, setHubSpotResponse] = useState<{
    message: string;
    statusCode: number;
  }>({ message: '', statusCode: 0 });

  const historyState = history.location.state as { refUri: string };

  useEffect(() => {
    selectedApp &&
      ai.trackPageView({
        name: `App Details`,
        properties: {
          'Selected app': selectedApp.name,
        },
        refUri: historyState ? historyState.refUri : undefined,
      });
  }, [ai, selectedApp, historyState]);

  useEffect(() => {
    if (!showSnackbar) {
      setHubSpotResponse({ message: '', statusCode: 0 });
    }
  }, [showSnackbar, showModal]);

  async function postHubSpotForm(formValues: HubSpotFormFormat) {
    Axios.post(hubSpotFormUrl, formValues)
      .then((res: AxiosResponse<{ inlineMessage: string }>) => {
        return (
          ai.trackEvent({ name: hubSpotFormSubmittedEvent }),
          setShowModal(false),
          setShowSnackbar(true),
          setShowAlert(true),
          setHubSpotResponse({
            message: res.data.inlineMessage,
            statusCode: res.status,
          }),
          setTimeout(() => {
            setHubSpotResponse({ message: '', statusCode: 0 });
          }, 5000)
        );
      })
      .catch((error: AxiosError) => {
        return (
          setShowSnackbar(true),
          setHubSpotResponse({
            message:
              'There was an issue submitting the form. Please try again later.',
            statusCode: error.response?.status as number,
          }),
          setTimeout(() => {
            setHubSpotResponse({ message: '', statusCode: 0 });
          }, 5000)
        );
      });
  }

  const submitHandler = (formValues: CustomerDetailsFormat) => {
    const hubSpotFieldMapper: HubSpotFormFormat = Object.entries({
      ...formValues.customer,
      requested_app_name_in_marketplace: selectedApp!.name,
      partner_integration_reason: DOMPurify.sanitize(
        formValues.customer.partner_integration_reason,
        { ALLOWED_TAGS: [] },
      ),
    }).reduce(
      (acc, cur): HubSpotFormFormat => {
        acc.fields.push({ name: cur[0], value: cur[1] });
        return acc;
      },
      {
        fields: [],
        context: { pageName: selectedApp?.name, pageUri: window.location.href },
      } as HubSpotFormFormat,
    );
    postHubSpotForm(hubSpotFieldMapper);
  };

  if (isUninitialized || isLoading) {
    return (
      <SplashScreen
        title="Loading app..."
        productName={marketplace}
        isFullPage={false}
        image={TalentechSvgImages.SCIENE_TECHNOLOGY_ROCKET}
      />
    );
  }

  if (isError) {
    return (
      <SplashScreen
        image={TalentechSvgImages.NOTIFICATIONS_INFORMATION}
        title="Error while loading the application"
        subtitle={
          'We could not fetch the application, try refreshing the page.'
        }
        productName={marketplace}
        isFullPage={false}
      />
    );
  }

  return (
    <>
      {selectedApp ? (
        <Page>
          <PageTitle
            title={selectedApp.name}
            breadcrumbs={[
              {
                label: 'Home',
                onClick: (e) => {
                  e.preventDefault();
                  dispatch(resetSearchAndFilters());
                  history.push({ pathname: AppRoutes.Home });
                },
              },
              {
                label: selectedApp.name,
                isActive: true,
              },
            ]}
          />
          <StyledGrid container>
            <div className={'row g-4'}>
              <div
                className={
                  'col-12 col-lg-9 order-2 order-lg-1 d-flex flex-column'
                }
              >
                {showAlert && (
                  <Alert
                    onClose={() => setShowAlert(false)}
                    title="Thanks for reaching out!"
                    message="We’ll have a look at your details and get back to you as soon as we can to give you all the information you need about this app and help you get up and running."
                    color={TalentechColors.INFO}
                    dismissible
                  />
                )}
                <AppDescription
                  shortDescription={selectedApp.shortDescription}
                  description={selectedApp.description}
                  detailsPageImageUrl={selectedApp.detailsPageImageUrl}
                  additionalContent={selectedApp.additionalContent}
                />
              </div>
              <div className={'col-12 col-lg-3 order-1 order-lg-2'}>
                <AppFacts
                  markets={selectedApp.markets}
                  languages={selectedApp.languages}
                  integratesWith={selectedApp.integratesWith}
                  appName={selectedApp.name}
                  logo={selectedApp.appIconUrl || defaultLogo}
                  showBorder={
                    !selectedApp.appIconUrl
                      ? false
                      : selectedApp.creator !== talentech
                  }
                  label={'Learn more'}
                  onClick={() => {
                    setHubSpotResponse({ message: '', statusCode: 0 });
                    setShowModal(true);
                    ai.trackEvent({
                      name: hubSpotFormModalOpenedEvent,
                      properties: {
                        'Selected app': selectedApp.name,
                      },
                    });
                  }}
                />
              </div>
            </div>
          </StyledGrid>
          <ModalForm
            modal={{
              title: 'Learn more',
              showModal: showModal,
              toggleModal: setShowModal,
            }}
            form={{
              handleCancel: () => {
                ai.trackEvent({ name: hubSpotFormModalClosedEvent });
              },
              formFields: IntegrationFormFields,
              handleSubmit: submitHandler,
              validationSchema: userSchema,
            }}
          />
          {hubSpotResponse.message !== '' && (
            <StyledSnackbar
              toggleSnackbar={showSnackbar}
              color={
                hubSpotResponse.statusCode === 200
                  ? TalentechColors.SUCCESS
                  : TalentechColors.DANGER
              }
              content={
                hubSpotResponse.statusCode === 200
                  ? hubSpotResponse.message
                  : 'There was an issue submitting the form. Please try again later.'
              }
            />
          )}
        </Page>
      ) : (
        <SplashScreen
          image={TalentechSvgImages.NOTIFICATIONS_INFORMATION}
          title="App not found"
          subtitle="Sorry, we couldn’t find the app you are looking for."
          productName={marketplace}
          isFullPage={false}
          customComponent={
            <Link to={AppRoutes.Home + search}>
              <Button
                label={'back to marketplace'}
                color={ButtonColors.PRIMARY}
              />
            </Link>
          }
        />
      )}
    </>
  );
};

export default AppDetails;
