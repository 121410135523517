import DOMPurify from 'dompurify';

type SanitizerProps = {
  dirtyContent: string | Node;
  allowedTags: Array<string>;
  className?: string;
};

// These hooks force clicked links to be opened in a new tab
const TEMP_ATTR = 'data-temp-href-target';
DOMPurify.addHook('beforeSanitizeAttributes', (node) => {
  if (node.tagName === 'A') {
    if (!node.hasAttribute('target')) {
      node.setAttribute('target', '_blank');
    }
    if (node.hasAttribute('target')) {
      node.setAttribute(TEMP_ATTR, node.getAttribute('target')!);
    }
  }
});

DOMPurify.addHook('afterSanitizeAttributes', (node) => {
  if (node.tagName === 'A' && node.hasAttribute(TEMP_ATTR)) {
    node.setAttribute('target', node.getAttribute(TEMP_ATTR)!);
    node.removeAttribute(TEMP_ATTR);
    if (node.getAttribute('target') === '_blank') {
      node.setAttribute('rel', 'noopener');
    }
  }
});

export const SanitizedDirtyHtmlContent = ({
  dirtyContent,
  allowedTags,
  className,
}: SanitizerProps) => {
  return (
    <div
      className={className}
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(dirtyContent, {
          ALLOWED_TAGS: allowedTags,
        }),
      }}
    />
  );
};
