import { ApplicationBarLoggedOut, TalentechIcons } from '@talentech/components';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../utils';
import { marketplace } from '../../utils/constants';
import { resetSearchAndFilters } from '../../utils/features/filters/filterSlice';
import { useAppDispatch } from '../../utils/features/hooks';
import { MenuItems } from '@talentech/components/build/storybook-core/global/ApplicationBarLoggedOut/ApplicationBarLoggedOut';

type LoggedOutLayoutProps = {
  isProductExperienceEnabled: boolean;
};

const LoggedOutLayout: React.FC<LoggedOutLayoutProps> = ({
  isProductExperienceEnabled,
  children,
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();

  function handleLogin() {
    window.location.href = `${window.location.origin}${AppRoutes.Login}?returnUrl=${location.pathname}`;
  }

  const menuItems: MenuItems[] = [
    {
      label: 'Home',
      icon: TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_HOME_HOUSE_1,
      href: AppRoutes.Home,
      onClick: () => {
        dispatch(resetSearchAndFilters());
      },
      blank: false,
    },
    {
      label: 'Become a partner',
      icon: TalentechIcons.LIGHT_17_USERS_GEOMERTRIC_CLOSE_UP_MULTIPLE_USERS_ACTIONS_MULTIPLE_ACTIONS_HEART,
      href: 'https://content.talentech.com/join-our-partner-network',
      blank: true,
    },
    {
      label: 'Support',
      icon: TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_HELP_HEADPHONES_CUSTOMER_SUPPORT,
      href: 'https://talentech-support.freshdesk.com/en/support/tickets/new',
      blank: true,
    },
  ];

  return (
    <>
      <ApplicationBarLoggedOut
        applicationName={marketplace}
        menuItems={
          isProductExperienceEnabled
            ? [
                ...menuItems,
                {
                  label: 'Product experience',
                  icon: TalentechIcons.LIGHT_06_BUSINESS_PRODUCTS_BUSINESS_USER_NETWORK,
                  href: AppRoutes.ProductExperience,
                  blank: false,
                },
              ]
            : menuItems
        }
        loginProps={{
          enabled: true,
          label: 'Sign in',
          onClick: () => {
            handleLogin();
          },
        }}
      />
      {children}
    </>
  );
};

export default LoggedOutLayout;
