import {
  Button,
  ButtonColors,
  Grid,
  Icon,
  Modal,
  PageTitle,
  SplashScreen,
  TalentechIcons,
  TalentechSvgImages,
  Typography,
} from '@talentech/components';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { AppWidget } from '../../components/appCard/AppProvisioningCard';
import { AppRoutes, removeLastSlash } from '../../utils';
import { ExternalProductIds, marketplace } from '../../utils/constants';
import { useProvisionProductForTenantMutation } from '../../utils/features/services/ProvisioningService';
import { useGetTenantProductsQuery } from '../../utils/features/services/TenantService';
import { useGetUserInfoQuery } from '../../utils/features/services/UserService';

const AppWidgetWrapper = styled.div`
  &,
  & > a {
    text-decoration: none !important;
  }

  border: 1px solid;
  border-color: rgba(0, 0, 0, 0);

  &:hover,
  &:focus {
    border-color: rgb(38, 37, 62);
  }

  &:focus {
    box-shadow: 0px 0px 1px 2px rgba(52, 52, 52, 0.18);
  }
`;

export const SelectProductWidget = () => {
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { data: loggedInUser, isLoading } = useGetUserInfoQuery();
  const { data: tenantProducts } = useGetTenantProductsQuery(undefined, {
    skip: !loggedInUser?.isAuthenticated,
  });

  const [provisionProductForTenant, { isLoading: isSubmitting, isSuccess }] =
    useProvisionProductForTenantMutation();

  const tenantProductList = () => {
    const products = Object.fromEntries(
      Object.entries(ExternalProductIds).filter(([key]) =>
        tenantProducts?.map((p) => p.talentechExternalId).includes(key),
      ),
    );
    const productName = Object.values(products);
    return productName;
  };

  useEffect(() => {
    if (isSuccess) {
      history.push(
        removeLastSlash(AppRoutes.ProductExperience) +
          AppRoutes.PeopleCoreSetupCompleted,
      );
    }
  }, [isSuccess, history]);

  if (isLoading) {
    return (
      <SplashScreen
        productName={marketplace}
        title={'Loading...'}
        image={TalentechSvgImages.SCIENE_TECHNOLOGY_ROCKET}
        productIcon={TalentechSvgImages.PRODUCTS_LIGHT_TALENTECH}
        isFullPage={false}
      />
    );
  }

  return (
    <>
      <PageTitle
        breadcrumbs={[
          {
            label: 'Home',
            onClick: (e) => {
              e.preventDefault();
              history.push({ pathname: AppRoutes.Home });
            },
          },
          {
            label: 'Product experience',
            isActive: true,
          },
        ]}
        title="Experience us for yourself"
        description="From recruitment to onboarding and talent management, our products will automate your processes and upgrade your employee experience so you can outperform your competition. You can pick and choose, and build a personalized tech suite that solves YOUR unique challenges. And because all our solutions are connected, your candidates move seamlessly through the talent pipeline, with HR in the driver’s seat. Select the product you would like to try out today we'll get you up and running in no time."
      />
      <Grid className="my-3" container>
        {loggedInUser?.isAuthenticated && (
          <>
            <Typography variant="h5" className="m-0">
              Your apps
            </Typography>
            <div className="row gx-5 gy-3">
              {tenantProductList() && tenantProductList()?.length > 0 ? (
                tenantProductList().map((p) => (
                  <div className="col-12 col-sm-6 col-lg-3">
                    <AppWidget name={p} />
                  </div>
                ))
              ) : (
                <Typography>
                  Your workspace has no apps. Get started by selecting one of
                  our available applications.
                </Typography>
              )}
            </div>
            <br />
          </>
        )}

        <Typography variant="h5">Available apps</Typography>
        <div className="row gx-5">
          <div className="col-12 col-sm-6 col-lg-3">
            {tenantProductList().includes('People Core') ? (
              <AppWidget
                isInstalled={true}
                name={'People Core'}
                description={
                  <div className="d-flex align-items-center">
                    <Icon
                      icon={
                        TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_ALERTS_CHECKMARK
                      }
                    />
                    <span className="ms-2">Already installed</span>
                  </div>
                }
              />
            ) : loggedInUser?.isAuthenticated ? (
              <AppWidget
                name={'People Core'}
                customContent={
                  <>
                    <br />
                    <Button
                      label="Add to your workspace"
                      color={ButtonColors.PRIMARY}
                      outline
                      onClick={() => setShowModal(true)}
                    />
                  </>
                }
              />
            ) : (
              <a
                className="cursor-pointer"
                style={{ textDecoration: 'none' }}
                href={AppRoutes.RequestSandbox.replace(':id', 'people-core')}
              >
                <AppWidgetWrapper className="position-relative d-flex h-100 rounded bg-white">
                  <AppWidget name="People Core" />
                </AppWidgetWrapper>
              </a>
            )}
          </div>
        </div>
      </Grid>

      <Modal
        title={'Confirm setup'}
        showModal={showModal}
        onClose={() => setShowModal(false)}
        buttons={[
          <Button
            label={'Cancel'}
            color={ButtonColors.PRIMARY}
            outline
            onClick={() => setShowModal(false)}
          />,
          <Button
            color={ButtonColors.SUCCESS}
            label={isSubmitting ? 'Adding..' : 'Confirm'}
            isLoading={isSubmitting}
            disabled={isSubmitting}
            onClick={() => provisionProductForTenant()}
          />,
        ]}
        children={
          <>
            <SplashScreen
              isFullPage={false}
              productName={''}
              title={''}
              subtitle={
                'Please confirm that you would like to add People Core to your workspace.'
              }
              image={TalentechSvgImages.ALERT_ALERT_INFO}
            />
          </>
        }
      />
    </>
  );
};
