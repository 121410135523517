import {
  Icon,
  SplashScreen,
  TalentechIcons,
  TalentechSvgImages,
  Typography,
} from '@talentech/components';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from '../../utils';
import { marketplace } from '../../utils/constants';
import { getPeopleCoreUrl } from '../../utils/envUtils';
import { useGetUserDetailsFromCacheQuery } from '../../utils/features/services/ProvisioningService';

export const ActivateSandboxEnvironment = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const contextId = urlParams.get('contextId');

  const { isError, isLoading, isUninitialized } =
    useGetUserDetailsFromCacheQuery(contextId ? contextId?.toString() : '');

  if (!isUninitialized && isLoading) {
    return (
      <SplashScreen
        title="Loading..."
        productName={marketplace}
        isFullPage={true}
        image={TalentechSvgImages.SCIENE_TECHNOLOGY_ROCKET}
      />
    );
  }

  if (isError) {
    return (
      <SplashScreen
        title="Internal server error"
        subtitle="We encountered an issue while attempting to activate your account."
        productName={marketplace}
        isFullPage={false}
        productIcon={TalentechSvgImages.PRODUCTS_LIGHT_TALENTECH}
        image={TalentechSvgImages.NOTIFICATIONS_INFORMATION}
        customComponent={
          <>
            <Typography variant="p"></Typography>
            <a
              className="mx-2 btn btn-outline-secondary"
              href={AppRoutes.RequestSandbox.replace(':id', 'people-core')}
            >
              Try requesting your app again
            </a>
          </>
        }
      />
    );
  }

  return (
    <SplashScreen
      image={TalentechSvgImages.NOTIFICATIONS_SUCCESS_CHECK}
      isFullPage={false}
      productName="Marketplace"
      subtitle="You have successfully confirmed your email. Your People Core experience awaits you."
      title="Success"
      customComponent={
        <>
          <a
            className="mx-2 btn btn-light"
            href={getPeopleCoreUrl(window.location.origin)}
            target="_blank"
            rel="noreferrer"
          >
            Get started with people core{' '}
            <Icon
              className="ms-2"
              icon={
                TalentechIcons.LIGHT_01_INTERFACE_ESSENTIALS_EXPAND_RETRACT_EXPAND_6
              }
            />
          </a>
        </>
      }
    />
  );
};
