import {
  Grid,
  PageTitle,
  Row,
  SplashScreen,
  TalentechSvgImages,
} from '@talentech/components';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from '../../utils';
import { getPeopleCoreUrl, getTalentechAdminUrl } from '../../utils/envUtils';

export const SetupCompleted = () => {
  const history = useHistory();

  return (
    <>
      <PageTitle
        title={'Setup completed'}
        breadcrumbs={[
          {
            label: 'Home',
            onClick: (e) => {
              e.preventDefault();
              history.push({ pathname: AppRoutes.Home });
            },
          },
          {
            label: 'Product experience',
            onClick: (e) => {
              e.preventDefault();
              history.push({ pathname: AppRoutes.ProductExperience });
            },
          },
          {
            label: 'Setup completed',
            isActive: true,
          },
        ]}
      />
      <SplashScreen
        isFullPage={false}
        productName={''}
        title={'Success!'}
        subtitle={
          'People Core was successfully added as a product to your workspace. '
        }
        image={TalentechSvgImages.NOTIFICATIONS_SUCCESS_CHECK}
        customComponent={
          <Grid>
            <Row>
              <div className="col-auto">
                <span className="link-primary">
                  <a href={getTalentechAdminUrl(window.location.origin)}>
                    Go to Talentech Admin
                  </a>
                </span>
              </div>
              <div className="col-auto">
                <span className="link-primary">
                  <a href={getPeopleCoreUrl(window.location.origin)}>
                    Go to People Core
                  </a>
                </span>
              </div>
            </Row>
          </Grid>
        }
      />
    </>
  );
};
