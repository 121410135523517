import { AppDetails } from '../../interfaces';
import { baseApi } from './baseApi';

type AppDetailsResponse = AppDetails[];

const appsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getApps: builder.query<AppDetailsResponse, void>({
      query: () => '/Apps',
    }),
  }),
});

export const { useGetAppsQuery } = appsApi;
