import { Typography } from '@talentech/components';
import styled from 'styled-components';

type HeroProps = {
  title: string;
  subtitle: string;
  image: string;
};

const HeroContent = styled.div<{ bgImage: string }>`
  background-image: url(${(props) => props.bgImage});
  background-position: center 52%;
  height: 16rem;
  width: 100%;
  border-radius: 0.25rem;
  box-shadow: 0rem 0.125rem 0.125rem rgba(21, 21, 23, 0.04);

  @media (max-width: 22.75rem) {
    background-size: cover;
  }
`;

const StyledText = styled.div`
  white-space: pre-line;
  margin: auto;

  @media (min-width: 23rem) {
    margin-left: 9%;
  }
  @media (min-width: 48rem) {
    margin-left: 6.1875rem;
  }
  @media (min-width: 90rem) {
    margin-left: 11.8125rem;
  }
`;

const Title = styled(Typography)`
  font-size: clamp(2rem, 1.7234rem + 1.2158vw, 3rem);
`;
const Subtitle = styled(Typography)`
  font-size: clamp(0.875rem, 0.7021rem + 0.7599vw, 1.5rem);
`;

const Wrapper = styled.div`
  @media (min-width: 75rem) {
    margin-bottom: 2.5rem;
  }
`;

const Hero = ({ title, image, subtitle }: HeroProps) => {
  return (
    <Wrapper>
      <HeroContent bgImage={image} className={'d-flex flex-column'}>
        <StyledText>
          <Title className="text-white mb-0" variant="h1">
            {title}
          </Title>
          <Subtitle className="text-white mb-0 fw-light">{subtitle}</Subtitle>
        </StyledText>
      </HeroContent>
    </Wrapper>
  );
};

export default Hero;
