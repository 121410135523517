import { Select } from '@talentech/components';
import { marketOptions } from '../../utils/constants';
import { setSelectedMarket } from '../../utils/features/filters/filterSlice';
import { useAppDispatch } from '../../utils/features/hooks';
import { SectionTitle } from '../../utils/styles';
import { Wrapper } from './StyledWrapper';

interface Props {
  market: string;
}

const FilterByMarket = ({ market }: Props) => {
  const dispatch = useAppDispatch();
  const selectedMarket = marketOptions.find(
    (_market) => _market.value === market,
  );
  return (
    <Wrapper>
      <SectionTitle size={'small'}>MARKET</SectionTitle>
      <Select
        aria-label="Market"
        options={marketOptions}
        value={selectedMarket}
        // @ts-ignore
        onChange={(selection: { label: string; value: string }) => {
          if (selection.value !== market) {
            dispatch(setSelectedMarket(selection.value));
          }
        }}
      />
    </Wrapper>
  );
};
export default FilterByMarket;
