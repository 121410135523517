import { Typography } from '@talentech/components';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { truncateLongText } from '../../utils';
import { AppRoutes } from '../../utils/enums';
import { TagBox } from '../../utils/styles';
import { integrationOptions } from '../../utils/constants';

type HomePageAppCardProps = {
  logo: ReactNode;
  name: string;
  id: string;
  description: string;
  integratesWith: Array<string>;
  urlFriendlyName: string;
  className: string;
};

const StyledHomePageAppCards = styled.div`
  border-radius: 0.25rem;
  box-shadow: 0px 2px 2px rgba(21, 21, 23, 0.04),
    0px 0px 2px rgba(21, 21, 23, 0.04);

  &:hover {
    box-sizing: border-box;
    outline: 1px solid #60606c;
    box-shadow: 0px 1px 2px rgba(21, 21, 23, 0.3),
      0px 2px 6px rgba(21, 21, 23, 0.15);
  }
  :active,
  :focus {
    outline: 1px solid #60606c;
    box-shadow: 0 0 0 0.25rem #c7c7dc !important;
  }
`;

const HomePageAppCard = ({
  logo,
  name,
  id,
  description,
  urlFriendlyName,
  integratesWith,
  className,
}: HomePageAppCardProps) => {
  const integrations = integrationOptions.filter((productRegister) =>
    integratesWith.includes(productRegister.value),
  );

  return (
    <div className={className}>
      <Link
        style={{ textDecoration: 'none' }}
        to={{
          pathname: encodeURI(
            AppRoutes.Apps.replace(':id', id).replace(':name', urlFriendlyName),
          ),
          state: { refUri: window.location.href as string },
        }}
      >
        <StyledHomePageAppCards
          className={'d-flex flex-column p-4 bg-white h-100'}
        >
          {logo}
          <Typography variant="h3" className={'text-300 fs-4'}>
            {name}
          </Typography>
          <Typography variant="p" className={'text-200'}>
            {truncateLongText(description)}
          </Typography>
          <div className={'d-flex flex-wrap mt-auto'}>
            {integrations.map((app, index) => (
              <TagBox className={'text-uppercase text-300'} key={index}>
                <Typography variant="p" className="m-0">
                  {app.label}
                </Typography>
              </TagBox>
            ))}
          </div>
        </StyledHomePageAppCards>
      </Link>
    </div>
  );
};

export default HomePageAppCard;
