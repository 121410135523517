import { object, string } from 'yup';

export const userSchema = object().shape({
  customer: object().shape({
    firstname: string().required('First name is required'),
    lastname: string().required('Last name is required'),
    email: string()
      .email('Must be a valid email')
      .required('Email is required'),
    partner_integration_reason: string().notRequired(),
  }),
});

export const ProductExperienceRegistrationSchema = object().shape({
  requestSandbox: object().shape({
    firstName: string().required('Required'),
    lastName: string().required('Required'),
    companyName: string().required('Required'),
    email: string().email('Invalid email').required('Required'),
  }),
});
