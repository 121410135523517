import { Row } from '@talentech/components';
import { AppDetails } from '../../utils/interfaces';
import { LabelProps, SectionTitle } from '../../utils/styles';
import Cards from '../appCard/Cards';

type AppGroupProps = {
  label: string;
  labelSize?: LabelProps;
  apps: AppDetails[];
};

export const AppGroup = (props: AppGroupProps) => {
  return (
    <div className={'my-4 pb-md-4'}>
      <SectionTitle size={props.labelSize?.size}>{props.label}</SectionTitle>
      <Row gy={4}>
        <Cards filteredApps={props.apps} />
      </Row>
    </div>
  );
};
