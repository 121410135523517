import { Typography } from '@talentech/components';
import styled from 'styled-components';

export type LabelProps = {
  size?: 'small' | 'large';
};

export const TagBox = styled.div`
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border: 1px solid rgba(38, 37, 62, 1);
  font-weight: 600;
  }
`;

export const DottedDivider = styled.div`
  border-top: 1px dashed #d6d6dc;
`;

export const SectionTitle = styled(Typography).attrs((props: LabelProps) => ({
  className:
    props.size === 'large'
      ? 'text-300 fw-bold mb-2 mb-md-4'
      : 'text-200 fw-semi-bold mb-1',
}))`
  font-size: ${(props: LabelProps) =>
    props.size === 'large' ? '18px' : '12px'};
`;
export const StyledNotificationWrapper = styled.div`
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 1;
`;
